<template>
<div>
    <div class=" align-center" style=" margin-top: -36px;">
                <h5 v-if ="mainTab" id="data" style="padding:15px"> الدورات المسجل فيها الطالب : {{studentName}}
                        </h5>
                        <h5 v-if="receiptsTab"  id="data" style="padding:15px">   إيصالات الطالب : {{studentName}}
                        </h5>
                          <h5 v-if="coursesRegister"  id="data" style="padding:15px">    الدورات الغير مسجل فيها الطالب : {{studentName}}
                        </h5>
              <div v-if ="mainTab" class ="text-center padding col-lg-12 mr-auto courses-modal overflow-x">
                <table class="table b-block table-responsive">
                  <thead>
                    <tr>
                      <th> رقم </th>
                      <th>  الدورة</th>
                       <th>  التكلفة</th>
                        <th>  المادة</th>
                         <th>  المدرس</th>
                          <th>  محاضرات اليوم</th>
                         <th>   المسدد</th>
                         <th>   المتبقي</th>
                          <th>  الحضور</th>
                        <th>  الحضور الإستثنائي</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr v-for="course in enrolledCourses" :key="course.id">
                      <td>
                        {{ course.number }}
                      </td>
                      <td>
                        <span  class="course-name">{{ course.name }}</span>
                      </td>
                        <td>
                        {{ course.total_cost }}
                      </td>
                      <td>
                        {{ course.subject.name }}
                      </td>
                      <td>
                        {{ course.instructor.name }}
                      </td>
                      <td>
                        {{ }}
                      </td>
                       <td :class="{'paid-back' :course.payed> 0}">
                        {{ course.payed}}
                      </td>
                       <td>
                        {{ course.remain }}
                      </td>
                      <td>
                        {{ course.attended_count}}
                      </td>
                      <td>
                        {{ course.exceptional_count}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
             <div v-if ="mainTab" class="row">
              <div class="text-center col-lg-4">
                    <vmd-button
                      class="my-4 mb-2"
                      variant="gradient"
                      color="secondary"
                      @click="showReceiptTab"
                    >
                        الإيصالات</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-4">
                    <vmd-button
                      class="my-4 mb-2"
                      variant="gradient"
                      color="info"
                      @click="getUnrolledCourses"
                    >
                      التسجيل في دورات اخرى</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-4">
                    <vmd-button
                      type="reset"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="danger"
                      @click="$emit('hide-modal2')"
                    >
                      اغلاق
                    </vmd-button>
                  </div>
                </div>
                <!-- start receipt tab -->
             <div v-if ="receiptsTab" class ="text-center col-lg-11 mr-auto courses-modal overflow-x">
                   <div class='row'>
                     <div class="mb-3 col-lg-6">
                    <label> الدورات المسجل فيها الطالب <span class="red">*</span>
                    <span color='danger' class="error">{{errors.grade_id?errors.grade_id[0]:''}}</span>
                    </label>
                      <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedCourse"
                        @select='getReceipts($event)'
                        :options='enrolledCourses'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الدورة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                       </div>
                        <div class='row times' style="margin-top:0px !important;margin-bottom:10px">
                <div class="col-md-4" >
                  <h5>الدورة : <span class="green">{{selectedCourse.number}} - {{selectedCourse.name}}</span></h5>
                </div>
              </div>
                <table class="table b-block table-responsive">
                  <thead>
                    <tr>
                      <th> رقم الإيصال </th>
                      <th>  نوع الإيصال</th>
                       <th>  التاريخ</th>
                        <th>  القيمة</th>
                    </tr>
                  </thead>
                  <tbody v-if="courseReceipts[0]">
                    <tr v-for="receipt in courseReceipts[0].receipts" :key="receipt.id">
                      <td>
                        {{ receipt.code }}
                      </td>
                        <td>
                        {{ receipt.transaction_type_name }}
                      </td>
                      <td>
                        {{ receipt.created_at }}
                      </td>
                      <td>
                        {{ receipt.amount }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                  <h6 v-if="courseReceipts[0]">{{!courseReceipts[0].receipts.length ?'لايوجد إيصالات للدورة' :''}}</h6>
                 <div class="row justify-content">
              <div class="text-center col-lg-4">
                    <vmd-button
                      class="my-4 mb-2"
                      variant="gradient"
                      color="warning"
                      @click="goMainTab"
                    >
                        رجوع </vmd-button
                    >
                  </div>
                </div>
              </div>
               <!-- submit courses tab -->
              <div v-if='coursesRegister'>
                <div class='row third-r'>
                     <div class="mb-3 col-lg-6">
                    <label> الدورات <span class="red">*</span>
                    <span color='danger' class="error">{{errors.grade_id?errors.grade_id[0]:''}}</span>
                    </label>
                      <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedUnEnrolled"
                        :options='unenrolledCourse'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الدورة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                <div class="mb-3 col-lg-3">
                  <vmd-button
                  style="width:100%"
                  @click.prevent="doAlertRegister()"
                    class=" mb-2"
                    variant="gradient"
                    color="warning"
                    > تسجيل</vmd-button
                  >
                </div>
                <div class ='row d-flex justify-content-center'>
                   <div class="text-center col-lg-4">
                    <vmd-button
                      type="reset"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="danger"
                      @click="closeRegister"
                    >
                      اغلاق
                    </vmd-button>
                  </div>
                  </div>
                       </div>
              </div>
                </div>
    </template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'
export default {
  name: 'studentCourses',
  components: {
    VmdButton
  },
  props: {
    enrolledCourses: {
      type: Array
    },
    studentName: {
      type: String
    },
    studentReceipts: {
      type: Array
    },
    studentId: {
      type: String
    }
  },
  data () {
    return {
      mainTab: true,
      courses: [],
      selectedCourse: '',
      receiptsTab: false,
      courseReceipts: [],
      coursesRegister: false,
      unenrolledCourse: [],
      selectedUnEnrolled: '',
      errors: []
    }
  },
  created () {
  },

  methods: {
    showReceiptTab () {
      this.receiptsTab = true
      this.mainTab = false
      this.courseReceipts = []
      this.selectedCourse = ''
    },
    getReceipts ($event) {
      this.receiptsTab = true
      this.courseReceipts = this.enrolledCourses.filter((val) => {
        if (val.id === $event.id) {
          return val.receipts
        }
      })
    },
    goMainTab () {
      this.coursesRegister = false
      this.receiptsTab = false
      this.mainTab = true
    },
    getUnrolledCourses () {
      this.coursesRegister = true
      this.receiptsTab = false
      this.mainTab = false
      this.$http.get(`${this.$hostUrl}student/${this.studentId}/courses`)
        .then((response) => {
          this.unenrolledCourse = response.data.data
        })
    },
    doAlertRegister () {
      const formData = new FormData()
      formData.append('student_id', this.studentId)
      formData.append('course_id', this.selectedUnEnrolled.id)
      this.errors = this.postRequest(
        formData,
        this.$hostUrl + 'course/enroll',
        ''
      ).then((response) => {
        this.mainTab = true
        this.receiptsTab = false
        this.coursesRegister = false
        this.selectedUnEnrolled = ''
        this.$emit('hide-modal2')
        if (response.course_id.length) { swal(response.course_id[0]) }
      })
    },
    closeRegister () {
      this.mainTab = true
      this.receiptsTab = false
      this.coursesRegister = false
      this.selectedUnEnrolled = ''
      this.$emit('hide-modal2')
    }
  }
}
</script>
<style scoped>
:deep.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
:deep.modal-content {
  width: 50% ;
}
.third-r{
  margin: 40px 20px;
}
.third-r button{
  margin-top: 33px !important;
}
.paid-back{
 background: #baf2c5a1 !important;
}
.padding{
  padding:10px !important
}
</style>
